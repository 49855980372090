import { Size } from '@/utils/enums';
import { Autorenew } from '@mui/icons-material';
import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type SizeButton = Size | 'xl';

export type ButtonStyleProps = {
  size?: SizeButton;
  width?: 'stretch' | 'full';
  disable?: boolean;
  radius?: 'rounded' | 'circle' | 'flatLeft' | 'flatRight';
  iconPosition?: 'left' | 'right';
  color?:
    | 'primary'
    | 'secondary'
    | 'destructive'
    | 'accent'
    | 'primary-success'
    | 'secondary-success'
    | 'primary-error'
    | 'secondary-error'
    | 'secondary-clean-error'
    | 'primary-warning'
    | 'secondary-warning'
    | 'white'
    | 'no_border'
    | 'clean';
};

export const StyledButton = styled.button<{
  $theme: 'light' | 'dark' | undefined;
}>`
  ${({ theme, $theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin: unset;
      border: unset;
      box-sizing: border-box;
      font-family: ${theme.typography.fontFamily.inter};
      font-weight: ${theme.typography.fontWeight.medium};
      font-size: ${theme.typography.fontSize['14'].size};
      line-height: ${theme.typography.fontSize['14'].lineHeight};
      text-align: center;
      cursor: pointer;
      gap: ${theme.spacing['10']};

      &.size-sm {
        padding: ${theme.spacing['08']} ${theme.spacing['16']};
      }
      &.size-md {
        padding: ${theme.spacing['10']} ${theme.spacing['16']};
      }
      &.size-lg {
        padding: ${theme.spacing['10']} ${theme.spacing['16']};
        font-size: ${theme.typography.fontSize['18'].size};
        line-height: ${theme.typography.fontSize['18'].lineHeight};
      }
      &.size-xl {
        padding: ${theme.spacing['12']} ${theme.spacing['16']};
        font-size: ${theme.typography.fontSize['20'].size};
        line-height: ${theme.typography.fontSize['20'].lineHeight};
      }

      &.radius-rounded {
        border-radius: 0.5rem;
      }
      &.radius-circle {
        border-radius: 3.75rem;
      }
      &.radius-flatLeft {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0.5rem
        border-bottom-right-radius: 0.5rem
      }
      &.radius-flatRight {
        border-top-left-radius: 0.5rem
        border-bottom-left-radius: 0.5rem
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &.type-no_border {
        background: ${theme.colors.white};
        color: ${theme.colors.gray[900]};
        border: none;
      }
      &.type-no_border:hover {
        background: ${theme.colors.gray[50]};
      }
      &.type-no_border:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.gray[200]};
      }
      &.type-no_border.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-white {
        background: ${theme.colors.white};
        color: ${theme.colors.gray[900]};
        border: 1px solid ${theme.colors.gray[200]};
      }
      &.type-white:hover {
        background: ${theme.colors.gray[50]};
      }
      &.type-white:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.gray[200]};
      }
      &.type-white.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-secondary-clean-error {
        background-color: transparent;
        color: ${theme.newColors.textDangerTextDanger};
      }
      &.type-secondary-clean-error:hover {
        background: ${theme.colors.error[200]};
      }
      &.type-secondary-clean-error:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.error[50]};
      }
      &.type-secondary-clean-error.disable {
        background: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-primary {
        background: ${theme.newColors.surfaceAccentSurfaceAccent};
        border: 1px solid ${theme.newColors.surfaceAccentSurfaceAccentLight};
        color: ${theme.colors.white};
      }
      &.type-primary:hover {
        background: ${theme.newColors.surfaceAccentSurfaceAccentHover};
      }
      &.type-primary:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${
            theme.newColors.surfacePrimarySurfacePrimaryPressed
          };
      }
      &.type-primary.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-secondary {
        background: ${theme.newColors.backgroundSecondaryBgSecondary};
        color: ${theme.colors.black};
      }
      &.type-secondary:hover {
        background: ${theme.newColors.surfaceSecondarySurfaceSecondaryHover};
      }
      &.type-secondary:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px
            ${theme.newColors.surfaceAccentSurfaceAccentLightPressed};
      }
      &.type-secondary.disable {
        background: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-destructive {
        background: transparent;
        color: ${
          $theme === 'dark' ? theme.colors.white : theme.colors.gray[900]
        };
        border: 1px solid transparent;
        padding: ${theme.spacing['08']} 0;
      }
      &.type-destructive:hover,
      &.type-destructive:focus {
        text-decoration: underline;
      }
      &.type-transparent.disable {
        color: ${
          $theme === 'dark' ? theme.colors.gray[200] : theme.colors.gray[400]
        };
        cursor: not-allowed;
      }

      &.type-accent {
        background: ${theme.colors.accent[400]};
        color: ${theme.colors.white};
      }
      &.type-accent:hover {
        background: ${theme.colors.accent[500]};
      }
      &.type-accent:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.accent[50]};
      }
      &.type-accent.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-primary-success {
        background: ${theme.newColors.surfaceSuccessSurfaceSuccess};
        color: ${theme.colors.white};
      }
      &.type-primary-success:hover {
        background: ${theme.newColors.surfaceSuccessSurfaceSuccessLightHover};
      }
      &.type-primary-success:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.success[50]};
      }
      &.type-primary-success.disable {
        background-color: ${
          theme.newColors.surfaceSuccessSurfaceSuccessDisabled
        };
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-secondary-success {
        background: ${theme.colors.success[100]};
        color: ${theme.colors.success[700]};
      }
      &.type-secondary-success:hover {
        background: ${theme.colors.success[200]};
      }
      &.type-secondary-success:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.success[50]};
      }
      &.type-secondary-success.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-primary-error {
        background: ${theme.colors.error[600]};
        color: ${theme.colors.white};
      }

      &.type-primary-error:hover {
        background: ${theme.colors.error[700]};
      }

      &.type-primary-error:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.error[50]};
      }

      &.type-primary-error.disable {
        background: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-secondary-error {
        background: ${theme.colors.error[100]};
        color: ${theme.colors.error[700]};
      }

      &.type-secondary-error:hover {
        background: ${theme.colors.error[200]};
      }

      &.type-secondary-error:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.error[50]};
      }

      &.type-secondary-error.disable {
        background: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      &.type-primary-warning {
        background: ${theme.colors.warning[400]};
        color: ${theme.colors.white};
      }
      &.type-primary-warning:hover {
        background: ${theme.colors.warning[500]};
      }
      &.type-primary-warning:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.warning[50]};
      }
      &.type-primary-warning.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }
      &.type-secondary-warning {
        background: ${theme.colors.warning[100]};
        color: ${theme.colors.warning[700]};
      }
      &.type-secondary-warning:hover {
        background: ${theme.colors.warning[200]};
      }
      &.type-secondary-warning:focus {
        box-shadow: ${theme.shadows['xs']},
          0px 0px 0px 4px ${theme.colors.warning[50]};
      }
      &.type-secondary-warning.disable {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[400]};
        cursor: not-allowed;
      }

      // CLEAN
      &.type-clean. {
        color: ${theme.colors.gray[700]};
        background-color: transparent;
        display: flex;
        justify-content: start;
      }
      &.type-clean:hover {
        background-color: ${theme.colors.gray[100]};
        color: ${theme.colors.gray[900]};
      }
      // CLEAN DANGER
      &.type-clean-danger {
        color: ${theme.colors.error[700]};
        background-color: transparent;
      }
      &.type-clean-danger:hover {
        background-color: ${theme.colors.error[50]};
        color: ${theme.colors.error[700]};
      }

      &.iconPosition-left {
        flex-direction: row;
      }
      &.iconPosition-right {
        flex-direction: row-reverse;
      }

      &.iconOnly.size-sm {
        padding: ${theme.spacing['08']};
        svg {
          width: ${theme.spacing['20']};
          height: ${theme.spacing['20']};
        }
      }
      &.iconOnly.size-md {
        padding: ${theme.spacing['10']};
        svg {
          width: ${theme.spacing['20']};
          height: ${theme.spacing['20']};
        }
      }
      &.iconOnly.size-lg {
        padding: ${theme.spacing['10']};
        svg {
          width: 24px;
          height: 24px;
        }
      }
      &.width-full {
        justify-content: start;
        width: 100%;
      }
      &.iconOnly.size-xl {
        padding: ${theme.spacing['12']};
        svg {
          width: 24px;
          height: 24px;
        }
      }
    `;
  }}
`;

export const LoadingIcon = styled(Autorenew)`
  animation: ${rotate} 1s linear infinite;
`;
