import {
  WarningAmber,
  Close,
  Adjust,
  InfoOutlined,
  ErrorOutline,
  CheckCircleOutlineOutlined,
  Loop,
  Brightness1,
  HelpOutlineOutlined,
} from '@mui/icons-material';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $rounded?: boolean; $copy: boolean }>`
  ${({ theme, $rounded, $copy }) => css`
    display: flex;
    cursor: ${$copy ? 'pointer' : 'default'};
    width: fit-content;
    align-items: center;
    border-radius: ${theme.spacing['06']};
    box-shadow: ${theme.shadows.xs};
    height: ${theme.spacing['24']};

    &.size-sm {
      padding: 2px 6px;
      height: ${theme.spacing['16']};

      &.variations-iconBadge {
        height: ${theme.spacing['24']};
        padding: ${theme.spacing['04']};
      }

      &.variations-notification {
        height: ${theme.spacing['04']};
        width: ${theme.spacing['04']};
        padding: 0;
      }
      &.variations-newBadge {
        border-radius: ${$rounded ? theme.spacing['10'] : theme.spacing['06']};
        padding: ${theme.spacing['08']} ${theme.spacing['08']};
      }
    }

    &.size-md {
      padding: 2px 6px;

      &.variations-notification {
        height: 18px;
        width: ${theme.spacing['16']};
        padding: 0;
      }
      &.variations-newBadge {
        border-radius: ${$rounded ? theme.spacing['12'] : theme.spacing['06']};
        padding: ${theme.spacing['04']} ${theme.spacing['06']};
      }
    }

    &.size-lg {
      padding: ${theme.spacing['04']} ${theme.spacing['10']};

      &.variations-iconBadge {
        height: ${theme.spacing['32']};
        padding: 6px;
      }
      &.variations-notification {
        height: ${theme.spacing['24']};
        padding: ${theme.spacing['04']} ${theme.spacing['08']};
        color: ${theme.colors.white};
      }
      &.variations-newBadge {
        height: ${theme.spacing['24']};
        padding: ${theme.spacing['04']} ${theme.spacing['06']};
        gap: ${theme.spacing['08']};
      }
    }

    &.size-xl {
      padding: ${theme.spacing['04']} ${theme.spacing['10']};
      height: ${theme.spacing['32']};
      &.variations-iconBadge {
        height: 56px;
        width: 56px;
        padding: ${theme.spacing['14']};
      }
      &.variations-newBadge {
        height: ${theme.spacing['04']};
        width: ${theme.spacing['04']};
        padding: 0;
      }
    }

    &.status-complete {
      background-color: ${theme.colors.gray[900]};
      &.contrast-low {
        background-color: ${theme.colors.white};
      }
    }

    &.status-inProgress {
      background-color: ${theme.colors.accent[500]};
      &.contrast-low {
        background-color: ${theme.colors.accent[100]};
        border: 1px solid ${theme.newColors.borderSecondaryBorderSecondary};
      }
    }

    &.status-success {
      background-color: ${theme.newColors.surfaceSuccessSurfaceSuccessLight};
      &.contrast-low {
        background-color: ${theme.newColors.surfaceSuccessSurfaceSuccessLight};
        border: 1px solid ${theme.newColors.borderSuccessBorderSuccess};
      }
    }

    &.status-error {
      background-color: ${theme.newColors.surfaceDangerSurfaceDangerLight};
      &.contrast-low {
        background-color: ${theme.newColors.surfaceDangerSurfaceDangerLight};
        border: 1px solid ${theme.newColors.borderDangerBorderDanger};
      }
    }

    &.status-warning {
      background-color: ${theme.colors.warning[400]};
      &.contrast-low {
        background-color: ${theme.newColors.surfaceWarningSurfaceWarningLight};
        border: 1px solid ${theme.newColors.borderWarningBorderWarning};
      }
    }

    &.status-refunded {
      background-color: ${theme.colors.accent[500]};
      &.contrast-low {
        background-color: ${theme.newColors.surfaceAccentSurfaceAccentLight};
        border: 1px solid ${theme.newColors.borderAccentBorderAccent};
      }
    }
    &.status-info {
      background-color: ${theme.newColors.surfaceWarningSurfaceWarningDisabled};
      &.contrast-low {
        background-color: ${theme.newColors.surfaceTertiarySurfaceTertiary};
        border: 1px solid ${theme.newColors.borderSecondaryBorderSecondary};
      }
      &.contrast-high {
        background-color: ${theme.newColors.surfaceTertiarySurfaceTertiary};
        border: 1px solid ${theme.newColors.borderSecondaryBorderSecondary};
        &:hover {
          background-color: ${theme.newColors
            .surfaceSecondarySurfaceSecondaryPressed};
        }
      }
    }
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    white-space: nowrap;
    &.size-sm {
      font-size: ${theme.typography.fontSize['12'].size};
      line-height: ${theme.typography.fontSize['12'].lineHeight};
      margin-left: ${theme.spacing['04']};
    }

    &.size-md {
      &.variations-notification {
        font-size: ${theme.typography.fontSize['12'].size};
        line-height: 12px;
        margin: 0 auto;
      }
    }

    &.size-lg {
      &.variations-notification {
        margin: 0 auto;
      }
    }
    &.status-complete {
      &.contrast-low {
        color: ${theme.colors.gray[900]};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }

    &.status-inProgress {
      &.contrast-low {
        color: ${theme.newColors.textAccentTextAccent};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }

    &.status-success {
      &.contrast-low {
        color: ${theme.newColors.textSuccessTextSuccess};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }

    &.status-error {
      &.contrast-low {
        color: ${theme.newColors.textDangerTextDanger};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }

    &.status-warning {
      &.contrast-low {
        color: ${theme.newColors.textWarningTextWarning};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }

    &.status-refunded {
      &.contrast-low {
        color: ${theme.newColors.textAccentTextAccent};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }
    &.status-info {
      &.contrast-low {
        color: ${theme.newColors.textTertiaryTextTertiary};
        font-weight: ${theme.typography.fontWeight.medium};
      }
      &.contrast-high {
        color: ${theme.newColors.textTertiaryTextTertiary};
        font-weight: ${theme.typography.fontWeight.medium};
      }
    }
  `}
`;

const commonIconStyles = css`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    &.size-sm {
      width: ${theme.spacing['16']};
      height: ${theme.spacing['16']};
    }
    &.size-md {
      width: ${theme.spacing['16']};
      height: ${theme.spacing['16']};
      &.variations-newBadge {
        width: fit-content;
        height: fit-content;
      }
    }
    &.size-lg {
      width: ${theme.spacing['20']};
      height: ${theme.spacing['20']};
      &.variations-newBadge {
        width: fit-content;
      }
    }
    &.size-xl {
      width: 28px;
      height: 28px;
    }

    &.status-success {
      &.contrast-low {
        color: ${theme.newColors.iconSuccessIconSuccessDisabled};
      }
    }
    &.status-error {
      &.contrast-low {
        color: ${theme.newColors.iconDangerIconDangerDisabled};
      }
    }
    &.status-warning {
      &.contrast-low {
        color: ${theme.newColors.iconWarningIconWarningDisabled};
      }
    }
    &.status-inProgress {
      &.contrast-low {
        color: ${theme.newColors.iconAccentIconAccentDisabled};
      }
    }
    &.status-refunded {
      &.contrast-low {
        color: ${theme.newColors.iconAccentIconAccentDisabled};
      }
    }

    &.status-notMapped {
      &.contrast-low {
        color: ${theme.colors.gray[500]};
      }
    }
    &.status-info {
      &.contrast-low {
        color: ${theme.newColors.iconPrimaryIconPrimaryHover};
      }
      &.contrast-high {
        color: ${theme.newColors.iconPrimaryIconPrimaryHover};
      }
    }
  `}
`;

export const SvgCircle = styled(Brightness1)`
  ${({ theme }) => css`
    && {
      color: ${theme.colors.white};
      height: ${theme.spacing['10']};
      width: ${theme.spacing['10']};

      &.status-complete {
        &.contrast-low {
          color: ${theme.newColors.iconPrimaryIconPrimaryMuted};
        }
      }

      &.status-notMapped {
        &.contrast-low {
          color: ${theme.newColors.iconPrimaryIconPrimaryMuted};
        }
      }

      &.status-inProgress {
        &.contrast-low {
          color: ${theme.newColors.iconAccentIconAccentDisabled};
        }
      }

      &.status-success {
        &.contrast-low {
          color: ${theme.newColors.iconSuccessIconSuccessDisabled};
        }
      }

      &.status-error {
        &.contrast-low {
          color: ${theme.newColors.iconDangerIconDangerDisabled};
        }
      }

      &.status-warning {
        &.contrast-low {
          color: ${theme.newColors.iconWarningIconWarningDisabled};
        }
      }

      &.status-refunded {
        &.contrast-low {
          color: ${theme.newColors.iconAccentIconAccentDisabled};
        }
      }
      &.status-info {
        &.contrast-low {
          color: ${theme.newColors.iconPrimaryIconPrimaryMuted};
        }
      }
    }
  `}
`;

export const SvgIconBadgeInfo = styled(InfoOutlined)`
  ${commonIconStyles}
`;

export const ChangeIconContainter = styled.div`
  ${commonIconStyles};
  svg {
    margin-top: 2px;
    width: 1rem;
    height: 1rem;
  }
`;

export const SvgIconClose = styled(Close)`
  ${commonIconStyles}
`;
export const SvgIconBadgeHighlight = styled(Adjust)`
  ${commonIconStyles}
`;

export const SvgIconBadgeSuccess = styled(CheckCircleOutlineOutlined)`
  ${commonIconStyles}
`;

export const SvgIconBadgeError = styled(ErrorOutline)`
  ${commonIconStyles}
`;

export const SvgIconBadgeWarning = styled(WarningAmber)`
  ${commonIconStyles}
`;

export const SvgIconBadgeRefunded = styled(Loop)`
  ${commonIconStyles}
`;

export const SvgIconHelper = styled(HelpOutlineOutlined)`
  ${commonIconStyles}
`;
